import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 321,
      sm: 550,
      md: 670,
      lg: 990,
      xl: 1040,
    },
  },
  components: {
    MuiMobileStepper: {
      styleOverrides: {
        dot: {
          backgroundColor: '#b9baba',
        },
        dotActive: {
          backgroundColor: '#007e47',
        },
      },
    },
  },
})

export default theme
