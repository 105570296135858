import React, { useEffect, useState } from 'react'
import Stepper from 'components/Stepper'
import ProjectCard from 'components/ProjectCard'
import { useLocation } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Carousel: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [parentReady, setParentReady] = useState(false)

  const [totalSteps, setTotalSteps] = useState(0)
  const [trackingEvents, setTrackingEvents] = useState<unknown[]>([])
  const [data, setData] = useState<Project[] | undefined>(undefined)
  const [error, setError] = useState<boolean>(false)

  const query = useQuery()

  const sendQueuedEventsToParent = () => {
    if (parentReady) {
      trackingEvents.forEach(trackingEvent => {
        sendTrackEventToParent(trackingEvent)
      })
      setTrackingEvents([])
    }
  }

  const projectTrackingInfo = (projectData: Project) => ({
    'adWidget.project_title': projectData?.Name?.toString(),
    'adWidget.project_id': projectData?.ProjectId,
  })

  const trackGA = (event: unknown, payload = {}) => {
    const trackEvent = {
      event,
      source: 'iframeChild',
      ...payload,
    }

    if (!parentReady) {
      setTrackingEvents([...trackingEvents, trackEvent])

      return
    }

    sendTrackEventToParent(trackEvent)
  }

  const sendTrackEventToParent = (event: unknown) => parent.postMessage(event, '*')

  useEffect(sendQueuedEventsToParent, [parentReady])

  const getAmountOfProjects = (projects: Project[]) => {
    if (projects.length > 5) return 5

    return projects.length
  }

  const initTracker = () => {
    // Maximum time in milliseconds to poll the parent frame for ready signal
    let maxTime = 2000
    const pollInterval = 200

    const postCallback = function (event: MessageEvent) {
      if (event.data !== 'parentReady') return

      if (event.data === 'parentReady' && !parentReady) {
        window.clearInterval(poll)
        setParentReady(true)
      }
    }

    const pollCallback = function () {
      // If maximum time is reached, stop polling
      maxTime -= pollInterval
      if (maxTime <= 0) window.clearInterval(poll)
      // Send message to parent that iframe is ready to retrieve Client ID
      window.parent.postMessage('childReady', '*')

      // Start listening for messages from the parent page
      window.addEventListener('message', postCallback)
    }

    // Start polling the parent page with "childReady" message
    const poll = window.setInterval(pollCallback, pollInterval)
  }

  useEffect(initTracker, [])

  useEffect(() => {
    async function getData() {
      try {
        let url = '/api/projects'
        const params = query.toString()
        if (params) {
          url = `${url}?${params}`
        }

        const result = await fetch(url)

        if (!result.ok) {
          const errorMessage = await result.text()
          throw new Error(errorMessage)
        }
        const projects: Project[] = await result.json()

        setTotalSteps(getAmountOfProjects(projects))
        setData(projects)

        trackGA('adWidget.impression', {
          ...projectTrackingInfo(projects[0]),
          'adWidget.slide_index': 1,
        })
      } catch (error) {
        setError(true)
        console.error(error)
      }
    }
    getData()
  }, [])

  const handleNext = () => {
    if (activeStep === totalSteps - 1) return

    const nextStep = activeStep + 1

    trackGA('adWidget.next_slide', {
      'adWidget.slide_index': nextStep + 1,
      'adWidget.slide_count': totalSteps,
    })

    setActiveStep(nextStep)
  }

  const handleBack = () => {
    if (activeStep === 0) return

    const previousStep = activeStep - 1

    trackGA('adWidget.previous_slide', {
      'adWidget.slide_index': previousStep + 1,
      'adWidget.slide_count': totalSteps,
    })

    setActiveStep(previousStep)
  }

  const handleStepChange = (step: number) => setActiveStep(step)

  if (error || !data) return null

  const trackProjectView = () => {
    if (data.length < 0) { return }

    trackGA('adWidget.impression', {
      ...projectTrackingInfo(data[activeStep]),
      'adWidget.slide_index': activeStep + 1,
    })
  }

  const handleClickOnProject = () => {
    if (data.length < 0) { return }

    trackGA('adWidget.click', {
      ...projectTrackingInfo(data[activeStep]),
      'adWidget.slide_index': activeStep + 1,
    })
  }

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <SwipeableViews
        axis={'x'}
        onChangeIndex={handleStepChange}
        onTransitionEnd={trackProjectView}
        index={activeStep}
        enableMouseEvents
      >
        {data.slice(0, totalSteps).map(project => (
          <ProjectCard key={project.ProjectId} project={project} onClick={handleClickOnProject} />
        ))}
      </SwipeableViews>
      <Stepper
        activeStep={activeStep}
        handleBack={handleBack}
        handleNext={handleNext}
        steps={totalSteps}
      />
    </div>
  )
}

export default Carousel
