import React from 'react'
import { MobileStepper } from '@mui/material'
import { Icon, IconButton } from '@hemnet/component-library'

type StepperProps = {
  activeStep: number
  handleNext: () => void
  handleBack: () => void
  steps: number
}

type NextBottomProps = {
  handleNext: () => void
}

type BackButtonProps = {
  handleBack: () => void
}

const NextButton: React.FC<NextBottomProps> = ({ handleNext }) => (
  <div style={{ transform: 'rotateY(180deg)', marginLeft: 8 }}>
    <IconButton onClick={handleNext} ariaLabel="go right" icon="chevronLeft">
      <Icon as="chevronLeft" />
    </IconButton>
  </div>
)

const BackButton: React.FC<BackButtonProps> = ({ handleBack }) => (
  <div style={{ marginRight: 8 }}>
    <IconButton onClick={handleBack} ariaLabel="go left" icon="chevronLeft">
      <Icon as="chevronLeft" />
    </IconButton>
  </div>
)

const Stepper: React.FC<StepperProps> = props => {
  const { activeStep, handleBack, handleNext, steps } = props
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <MobileStepper
        variant="dots"
        steps={steps}
        position="static"
        activeStep={activeStep}
        nextButton={<NextButton handleNext={handleNext} />}
        backButton={<BackButton handleBack={handleBack} />}
      />
    </div>
  )
}

export default Stepper
