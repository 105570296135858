import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Carousel from 'components/Carousel'
import theme from 'theme'

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Carousel />
    </ThemeProvider>
  )
}

export default App
