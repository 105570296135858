import React from 'react'
import { styled, Box } from '@mui/material'
import { Card, CardAction, Icon, PaddedContainer, Text } from '@hemnet/component-library'
import CardMedia from 'components/CardMedia'
import { ReactComponent as RiksbyggenLogo } from 'assets/riksbyggen.svg'
import buildUrlWithUtm from 'lib/buildUrlWithUtm'

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

const Logo = styled('div')({
  width: 118,
  height: 50,
})

const LinkIcon = styled('div')(({ theme }) => ({
  color: '#007e47',
  marginLeft: theme.spacing(1),
  height: 16,
}))

type ProjectCardProps = {
  project: Project
  onClick: () => void
}

const ProjectCard: React.FC<ProjectCardProps> = ({ onClick, project }) => (
  <Box sx={{ padding: 1 / 2 }}>
    <Card as="link" href={buildUrlWithUtm(project)} external openInNewTab onClick={onClick}>
      <CardMedia src={project.TeaserImage?.Url || ''} />
      <PaddedContainer>
        <Container>
          <Box>
            <Text styleType="medium">{project.Name || ''}</Text>
            <Text>{project?.Area || ''}</Text>
          </Box>
          <Logo>
            <RiksbyggenLogo />
          </Logo>
        </Container>
        <Container sx={{ height: 24 }}>
          <Text styleType="medium">
            {(project.Price?.Min &&
              `fr. ${parseInt(project.Price.Min, 10).toLocaleString('sv-SE')} kr`) ||
              ''}
          </Text>
          <Text styleType="medium">
            {(project.LivingArea?.Min &&
              project.LivingArea?.Max &&
              `${project.LivingArea.Min} - ${project.LivingArea.Max} m2`) ||
              ''}
          </Text>
          <Text styleType="medium">
            {(project.Room?.Min &&
              project.Room?.Max &&
              `${project.Room.Min} - ${project.Room.Max} rum`) ||
              ''}
          </Text>
        </Container>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline' }}>
          <CardAction>Läs mer om bostaden hos Riksbyggen</CardAction>
          <LinkIcon>
            <Icon as="externalLink" />
          </LinkIcon>
        </Box>
      </PaddedContainer>
    </Card>
  </Box>
)

export default ProjectCard
