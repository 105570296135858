const buildUrlWithUtm = (project: Project): string => {
  const baseUrl = 'https://track.adform.net/C/'
  const adFormId = 'bn=53361527'
  const cpdir = `cpdir=${project.Url}`
  const utmSource = 'utm_source=hemnet'
  const utmMedium = 'utm_medium=display'
  const utmCampaign = 'utm_campaign=central-bostadsbox'
  const utmContent = `utm_content=${project.ProjectId}, ${project.Name}, ${project.Municipality}, ${project.County}`

  const utmTags = `?${adFormId};${cpdir}&${utmSource}&${utmMedium}&${utmCampaign}&${utmContent}`

  return encodeURI(baseUrl + utmTags)
}

export default buildUrlWithUtm
