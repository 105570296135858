import React from 'react'
import { styled } from '@mui/material/styles'

type CardMediaProps = {
  src: string
}

const Div = styled('div')({ margin: 0, position: 'relative', height: 144 })

const Img = styled('img')({ width: '100%', height: 144, objectFit: 'cover' })

const CardMedia: React.FC<CardMediaProps> = props => (
  <Div>
    <Img src={props.src} />
  </Div>
)

export default CardMedia
